import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './index.css'
import Notifications from 'vue3-vt-notifications'
import VCalendar from 'v-calendar'
import VueGtag from "vue-gtag"
import VueTippy from "vue-tippy"
import './assets/tailwind.css'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/translucent.css'
import VueNumerals from 'vue-numerals'
import InstantSearch from 'vue-instantsearch/vue3/es'

createApp(App)
   .use(store)
   .use(router)
   .use(Notifications)
   .use(VCalendar)
   .use(VueNumerals)
   .use(InstantSearch)
   .use(VueTippy, {
      defaultProps: {
         theme: 'translucent',
      }
   })
   .use(VueGtag, {
      disableScriptLoad: process.env.VUE_APP_ENV !== 'production',
      config: {
         id: "G-VMFW7FGQZ8"
      },
   }, router)
   .mount('#app')
