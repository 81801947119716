import AuthService from "@/services/auth.service"
import ApiService from "@/services/api.service";
import GraphService from "@/services/graph.service"

function unregisterModules() {
   const modules = ['customs', 'colAssign', 'library', 'intacct', 'specifierUpdates', 'knowledgeBase'];
   modules.forEach((module) => {
      if (this.hasModule(module)) {
         this.unregisterModule(module);
      }
   });
}

export const auth = {
   namespaced: true,
   state() {
      return {
         msalConfig: {
            auth: {
               clientId: process.env.VUE_APP_AZURE_CLIENT_ID,
               authority: process.env.VUE_APP_AZURE_AUTHORITY,
               redirectUri: process.env.VUE_APP_AZURE_POST_LOGOUT_REDIRECT
            },
            cache: {
               cacheLocation: 'localStorage'
            },
            system: {
               iframeHashTimeout: 10000,
               windowHashTimeout: 10000,
               loadFrameTimeout: 10000
            }
         },
         account: undefined,
         tokenResponse: undefined,
         graphTokenResponse: undefined,
         isAuthenticated: false,
         icons: [],
         userLoadingState: 'initial',
         groups: [],
         user: {
            photo: undefined,
            dashboard: 'Default',
            menu: undefined,
            systemTheme: true,
            defaultTheme: 'theme-light',
            defaultLightTheme: 'theme-light',
            defaultDarkTheme: 'theme-dark',
            theme: undefined,
            prefersDark: false
         },
         impersonating: null
      }
   },
   getters: {
      name: (state) => {
         return state.impersonating?.name ? state.impersonating.name : state.account.name
      },
      username: (state) => {
         return state.impersonating?.user ? state.impersonating.user.toLowerCase() : state.account.username.toLowerCase()
      }
   },
   actions: {
      impersonate({ commit }, impersonating) {
         unregisterModules.call(this);
         commit('setImpersonating', impersonating)
      },
      stopImpersonating({ commit }) {
         unregisterModules.call(this);
         commit('setImpersonating', null)
      },
      saveUser({ commit, state }, settings) {
         let username = state.account.username
         if (state.impersonating?.user) {
            username = state.impersonating.user
         }
         return ApiService.post(`/user/${username}/update`, settings)
            .then(() => {
               commit('setUserData', settings)
               const currentTheme = settings.systemTheme ? state.user.prefersDark ? settings.defaultDarkTheme : settings.defaultLightTheme : settings.defaultTheme
               commit('setTheme', currentTheme)
            })
            .catch(error => Promise.reject(error))
      },
      setAccount({ commit }, account) {
         commit('setAccount', account)
      },
      setTheme({ commit }, theme) {
         commit('setTheme', theme)
      },
      setPrefersDark({ commit }, prefersDark) {
         commit('setPrefersDark', prefersDark)
      },
      getToken({ commit, state }) {
         let groups = []
         return AuthService.getToken(state.account, state.msalConfig.auth.clientId)
            .then(tokenResponse => {
               if (!Object.prototype.hasOwnProperty.call(tokenResponse.idTokenClaims, "groups")) {
                  if (state.groups.length === 0) {
                     GraphService.get(
                        `/me/memberOf/microsoft.graph.group?$search="displayName:Mi"`,
                        { headers: { ConsistencyLevel: 'eventual' } })
                        .then(response => {
                           groups = response.data.value.map(g => g.displayName)
                           commit('setGroups', groups)
                        })
                  }
               } else {
                  groups = tokenResponse.idTokenClaims.groups?.filter(g => g.startsWith('Mi'))
                  commit('setGroups', groups)
               }
               commit('setTokenResponse', tokenResponse)
            })
            .catch(error => Promise.reject(error))
      },
      getGraphToken({ commit, state }) {
         return AuthService.getGraphToken(state.account)
            .then(tokenResponse => [
               commit('setGraphTokenResponse', tokenResponse)
            ])
            .catch(error => Promise.reject(error))
      },
      getMenu({ commit }) {
         return ApiService.post(`/user/menu`)
            .then(response => {
               commit('setMenu', response.data)
            })
            .catch(error => Promise.reject(error))
      },
      getUserData({ commit, state }) {
         commit('userLoading')
         let body = {
            id: state.account.localAccountId,
            email: state.account.username
         };
         return ApiService.post(`/user/${state.account.username}`, body)
            .then(response => {
               if (response.data) {
                  commit('setUserData', response.data)
                  let theme = state.user.defaultTheme
                  if (state.user.systemTheme) {
                     theme = state.user.prefersDark ? state.user.defaultDarkTheme : state.user.defaultLightTheme;
                  }
                  commit('setTheme', theme)
               }
            })
            .catch(error => {
               commit('userFail')
               return Promise.reject(error)
            })
      },
      signIn({ commit }) {
         return AuthService.signIn()
            .then(({ account, tokenResponse }) => {
               commit('setAccount', account)
               commit('setTokenResponse', tokenResponse)
            })
            .catch(error => Promise.reject(error))
      },
      signOut({ commit }) {
         return AuthService.signOut()
            .then(() => {
               commit('setAccount', undefined)
               commit('setTokenResponse', undefined)
            })
            .catch(error => Promise.reject(error))
      }
   },
   mutations: {
      setImpersonating(state, impersonating) {
         state.impersonating = impersonating
      },
      setAccount(state, account) {
         state.account = account
         state.isAuthenticated = !!account
      },
      setTokenResponse(state, tokenResponse) {
         state.tokenResponse = tokenResponse
      },
      setGraphTokenResponse(state, tokenResponse) {
         state.graphTokenResponse = tokenResponse
      },
      userLoading(state) {
         state.userLoadingState = 'loading'
      },
      userFail(state) {
         state.userLoadingState = 'failed'
      },
      setUserData(state, userData) {
         state.userLoadingState = 'loaded'
         state.user = { ...state.user, ...userData }
      },
      setTheme(state, theme) {
         state.user.theme = theme
      },
      setPrefersDark(state, prefersDark) {
         state.user.prefersDark = prefersDark
      },
      setMenu(state, menu) {
         state.user.menu = JSON.parse(menu.menu).menu
         state.icons = JSON.parse(menu.icons).icons
      },
      setGroups(state, groups) {
         state.groups = groups
      }
   }
}
