<template>
   <notificationGroup group="default">
      <div class="fixed inset-0 z-50 flex items-end justify-end p-6 px-4 py-6 pointer-events-none">
         <div class="w-full max-w-sm">
            <notification v-slot="{ notifications, close }">
               <div v-for="notification in notifications" :key="notification.id">
                  <app-notification :notification="notification" @close-notification="close(notification.id)">
                     <template v-slot:title>{{ notification.title }}</template>
                     {{ notification.text }}
                  </app-notification>

               </div>
            </notification>
         </div>
      </div>
   </notificationGroup>

   <notificationGroup group="important">
      <div class="fixed inset-0 z-50 flex items-start justify-center p-6 px-4 py-6 pointer-events-none">
         <div class="w-full max-w-sm">
            <notification v-slot="{ notifications, close }">
               <div v-for="notification in notifications" :key="notification.id">

                  <app-notification :notification="notification" @close-notification="close(notification.id)">
                     <template v-slot:title>{{ notification.title }}</template>
                     {{ notification.text }}
                  </app-notification>

               </div>
            </notification>
         </div>
      </div>
   </notificationGroup>
</template>
<script>
import AppNotification from "@/components/AppNotification";
import NotificationGroup from "vue3-vt-notifications/src/VTNotificationGroup"
import Notification from "vue3-vt-notifications/src/VTNotification"

export default {
   name: 'AppNotifications',
   components: { AppNotification, NotificationGroup, Notification },
}
</script>
