import msal from "@/configs/msal.config"
import store from "@/store"

class AuthService {
    getToken(account, clientId) {
        const request = {
            account,
            scopes: [`${clientId}/.default`],
            redirectUri: process.env.VUE_APP_AZURE_REDIRECT
        }
        return msal.$msalInstance
            .acquireTokenSilent(request)
            .then(tokenResponse => tokenResponse)
            .catch(async () => {
                // if (error instanceof InteractionRequiredAuthError) {
                    return msal.$msalInstance
                        // .acquireTokenPopup(request)
                        // .acquireTokenRedirect(request)
                        .loginRedirect(request)
                        .then(tokenResponse => tokenResponse)
                        .catch(error => Promise.reject(error))
                // } else {
                //     return Promise.reject(error)
                // }
            })
    }

    getGraphToken(account) {
        const request = {
            account,
            scopes: [`https://graph.microsoft.com/Directory.Read.All`],
            redirectUri: process.env.VUE_APP_AZURE_REDIRECT
        }
        return msal.$msalInstance
            .acquireTokenSilent(request)
            .then(tokenResponse => tokenResponse)
            .catch(async () => {
                // if (error instanceof InteractionRequiredAuthError) {
                    return msal.$msalInstance
                        // .acquireTokenPopup(request)
                        // .acquireTokenRedirect(request)
                        .loginRedirect(request)
                        .then(tokenResponse => tokenResponse)
                        .catch(error => Promise.reject(error))
                // } else {
                //     console.error(error)
                //     return Promise.reject(error)
                // }
            })
    }

    async signIn() {
        return await msal.$msalInstance
            // .loginPopup({})
            .loginRedirect({})
            .then(tokenResponse => {
                const myAccounts = msal.$msalInstance.getAllAccounts()
                return {
                    account: myAccounts[0],
                    tokenResponse
                }
            })
            .catch(error => {
                return Promise.reject(`error during authentication: ${error}`)
            })
    }

    async signOut() {
        return await msal.$msalInstance
            // .logoutPopup({})
            .logoutRedirect({
                postLogoutRedirectUri: process.env.VUE_APP_AZURE_POST_LOGOUT_REDIRECT
            })
            .then(() => {})
            .catch(error => Promise.reject(error))
    }

    /**
     * @param {string|string[]} authGroup - The auth group(s) to check against
     * @returns {boolean}
     */
    hasAuth(authGroup) {
        if (typeof authGroup === 'string') {
            authGroup = [authGroup]
        }
        let userGroups = store.state.auth.groups
        if (store.state.auth.impersonating?.groups) {
            userGroups = store.state.auth.impersonating.groups.split(',')
        }
        const filteredGroups = userGroups
            .filter(group => authGroup.includes(group))
        return !!filteredGroups.length
    }
}

export default new AuthService()